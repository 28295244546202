<template>
    <div class="category-bar flex flex-start" v-if="categoryData && categoryData.length">
        <div class="category-bar-item" :class="[ index === 0 && 'category-bar-item-action' ]" @click="goToList({ code: 0 }, -1)">
            <p class="category-bar-item-text" :class="[ index === 0 && 'category-bar-item-text-action' ]">不限</p>
        </div>
        <div class="category-bar-item" :class="[ index === item.code && 'category-bar-item-action' ]" v-for="(item, i) in categoryData" :key="i" @click="goToList(item)">
            <p class="category-bar-item-text" :class="[ index === item.code && 'category-bar-item-text-action' ]">{{ item.name }}</p>
        </div>
    </div>
</template>

<script>
import service from "@/service";
import get from "@/utils/get";
import * as LocalData from "@/service/localData";

export default {
  name: "category-bar",
  props: {
    dtype: {
      type: String,
      default: "1",
    },
    autoJump: {
      type: Boolean,
      default: true
    },
    JumpTo: {
      type: Function,
      default: () => {}
    },
    productType: {
        type: [Number, String],
        default: 0
    }
  },
  data() {
    return {
      categoryData: [],
    };
  },
  computed: {
      index: {
          set (value) {
              this.$emit('update:productType', value)
          },
          get () {
              return this.productType
          }
      }
  },
  created() {
    service
      .r215037({
        dtype: this.dtype,
        depCity: LocalData.getOriginCity().cityName,
        depCityCode: LocalData.getOriginCity().cityCode,
      })
      .then((r) => get(r, "res.bd.data"))
      .then((data) => {
        this.categoryData = data;
      });
  },
  methods: {
    goToList(item) {
        this.index = item.code
        if (this.autoJump) {
            location.href = item.url;
        } else {
            this.JumpTo(item)
        }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/reset.scss";
@import "@/scss/style.scss";

.category-bar {
    width: 375 * $px;
    box-sizing: border-box;
    padding: 8 * $px;
    padding-right: 0;
    background: #ffffff;
    overflow-x: scroll;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
}

.category-bar-item {
    padding: 6 * $px 12 * $px;
    background: #f6f6f6;
    border-radius: 4 * $px;
    margin-right: 8 * $px;
}

.category-bar-item-action {
    background: rgba(25,133,255,0.10);
}

.category-bar-item-text {
    font-size: 13 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #262626;
}

.category-bar-item-text-action {
    color: #1985FF
}

.flex {
    display: flex;
    flex-direction: row;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.flex-between {
    justify-content: space-between;
}

.flex-around {
    justify-content: space-around;
}

.flex-column {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-start {
    justify-content: flex-start;
}

.flex-end {
    justify-content: flex-end;
}

.overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.iphone-notch {
    padding-top: constant(safe-area-inset-top); /* 兼容 iOS < 11.2 */
    padding-top: env(safe-area-inset-top); /* 兼容 iOS >= 11.2 */
}

.home-indicator {
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
}


</style>
