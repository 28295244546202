<template>
  <div class="tour-group">
    <c-loading :loading="isLoading">
      <!-- changeSearch: 目的地变更 -->
      <!-- changeDepSearch: 出发地变更 -->
      <!-- search: 文本输入 -->
      <navigation-bar ref="navigationBarRef" :type="10" :autoLoadSaveArrive="isArriveEmpty ? false : true" @changeSearch="handleSearchChangeArrive"
        @changeDepSearch="handleSearchChangeDeparture" @search="handleSearchChangeKeyword"></navigation-bar>

      <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit" :class="isIos?'mescroll':'mescrollAndriod'">

        <category-list dtype="2" :autoJump="false" :JumpTo="handleClickCategory"></category-list>

        <div ref="category"></div>

        <category-bar class="category-bar" v-show="!categoryIntersecting" dtype="2" :autoJump="false" :JumpTo="handleClickCategory"
          :productType.sync="productType"></category-bar>

        <div :style="{ height: px2rem(12) }"></div>

        <template v-if="isArriveEmpty">
          <recommend-card label="热门目的地" nomore>
            <div class="flex flex-center" slot="head" @click="getPopularDestinations">
              <p class="changePopularDestinations-text">换一换</p>
              <img class="changePopularDestinations-icon" src="@/assets/list/switch.png" alt="img">
            </div>
            <div class="flex flex-wrap flex-start">
              <template v-for="(item, index) in PopularDestinations">
                <block-item :key="'block-item' + index" :data="item" :noindex="PopularDestinationIndex !== 1"
                  :style="{ marginTop: index > 2 ? px2rem(20) : '' }" @click="handleChooseArrive(item)"></block-item>
                <div v-if="(index + 1) % 3 !== 0" :key="'block-item-div' + index" :style="{ width: px2rem(8) }"></div>
              </template>
            </div>
          </recommend-card>
          <div :style="{ height: px2rem(12) }"></div>
        </template>

        <div class="list-card" v-for="(item, index) in recommendList" :key="index" @click="JumpeToDetail(item)">
          <entity-item :data="item"></entity-item>
          <div v-if="index + 1 !== recommendList.length" :style="{ height: px2rem(17) }"></div>
        </div>

        <placeholder v-if="recommendList.length === 0"></placeholder>

      </mescroll-vue>
    </c-loading>
  </div>
</template>

<script>
import get from "@/utils/get";
import { px2rem } from "./mixins";
import * as LocalData from "@/service/localData";

import NavigationBar from "./components/navigation-bar";
import CategoryList from "@/components/category/category-list";
import CategoryBar from "@/components/category/category-bar";
import RecommendCard from "./components/recommend-card";
import BlockItem from "./components/block-item";
import EntityItem from "./components/entity-item";
import Placeholder from "@/components/placeholder";

import MescrollVue from "mescroll.js/mescroll.vue";
import nativeApi from "@/utils/nativeApi";

import service from "@/service";
import env from "@/utils/env";

export default {
  mixins: [px2rem],
  name: "tour-group",
  components: {
    NavigationBar,
    CategoryList,
    CategoryBar,
    RecommendCard,
    BlockItem,
    EntityItem,
    Placeholder,
    MescrollVue,
  },
  data() {
    return {
      // Common
      isLoading: true,
      isArriveEmpty: true, // true: 无目的地 false: 有目的地
      observer: null,
      categoryIntersecting: false,
      productType: 0,
      keywordStr: "",
      depCity: null,

      // Control
      PopularDestinationIndex: 0,
      PopularDestinationTotalPage: 0,

      // Data
      PopularDestinations: [],
      recommendList: [],

      mescroll: null,
      mescrollDown: {
        // use: false
      },
      mescrollUp: {
        callback: this.callbackScrollUp,
        noMoreSize: 0,
        // htmlNodata: '<p class="upwarp-nodata">_(:з」∠)_</p>',
      },
    };
  },
  computed: {
    isIos() {
      if (env.os == "ios") {
        return 1;
      } else {
        return 0;
      }
    },
  },
  methods: {
    init() {
      this.loadArrive()
        .then(this.getPopularDestinations)
        // .then(this.getRecommendList)
        .finally(() => (this.isLoading = false));
    },
    // 热门目的地
    async getPopularDestinations() {
      // 检查当前请求是否是最后一页
      if (
        this.PopularDestinationTotalPage > 0 &&
        this.PopularDestinationIndex === this.PopularDestinationTotalPage
      ) {
        // 回到第一页
        this.PopularDestinationIndex = 0;
      }
      const res = await service.r215039({
        pageSize: 6,
        pageNo: ++this.PopularDestinationIndex,
      });
      const data = get(res, "res.bd.data.cityList", []);
      this.PopularDestinationTotalPage = get(res, "res.bd.data.totalPage", 0);
      this.PopularDestinations = data.map((item, i) => ({
        raw: item,
        index: i,
        img: item.icon || "",
        name: item.cityName || "",
        desc: item.description || "",
        topTag: item.topTag || "",
      }));
    },
    // 推荐列表
    async getRecommendList(query = {}) {
      const res = await service.r215040({
        productType: this.productType,
        pageSize: 10,
        pageNo: 1,
        content: this.keywordStr || "",
        depCity: get(this.depCity, "cityName", LocalData.getOriginCity().cityName),
        depCityCode: get(this.depCity, "cityCode", LocalData.getOriginCity().cityCode),
        arrCity: this.keywordStr ? void 0 : (LocalData.getCity().default ? void 0 : LocalData.getCity().cityName),
        arrCityCode: this.keywordStr ? void 0 : (LocalData.getCity().default ? void 0 : LocalData.getCity().cityCode),
        ...query,
      });
      return get(res, "res.bd.data.products", []);
    },
    callbackScrollUp(page, mescroll) {
      this.getRecommendList({ pageNo: page.num, pageSize: page.size }).then(
        (data) => {
          const temp = data.map((item, i) => ({
            raw: item,
            img: item.productImageUrl || "",
            title: item.productName || "",
            desc: item.briefDesc || "",
            productTags: (item.productTags || [])
              .map((item) => item.name)
              .join(" | "),
            tags: (item.tags || []).map((tag) => ({
              text: tag.name,
              color: tag.colour,
            })),
            channelName: item.channelName || "",
            price: item.price || "--",
            pricePerfixed: item.pricePerfixed || "¥",
            priceSuffix: item.priceSuffix || "起",
            productId: item.productId || null,
          }));
          if (page.num === 1) {
            this.recommendList = temp;
          } else {
            this.recommendList = [...this.recommendList, ...temp];
          }
          // 当返回数据小于请求数据时，认为存在下一页
          mescroll.endSuccess(temp.length, temp.length < 10 ? false : true);
        }
      );
    },
    handleChooseArrive(item) {
      LocalData.setCity({
        cityName: item.raw.cityName,
        cityCode: item.raw.code,
      });
      if (this.$refs.navigationBarRef) {
        this.$refs.navigationBarRef.refreshArrive();
      }
      this.mescroll.resetUpScroll();
    },
    JumpTo(position) {
      const query = {
        // 出发地
        cityName: LocalData.getOriginCity().cityName,
        cityCode: LocalData.getOriginCity().cityCode,
      };
      const page = {
        url: "/",
        query,
      };

      // 带目的地跟团游列表
      if (position === "tour-group-with-arrive") {
        page.url = "/tour-group-with-arrive";
        page.name = "tour-group-with-arrive";
      }

      // this.$router.push(page);
      this.$page.push(page);
    },
    handleSearchChangeArrive(city) {
      console.log("------ handleSearchChangeArrive ------\n", city);
      this.mescroll.resetUpScroll();
    },
    handleSearchChangeDeparture(city) {
      console.log("------ handleSearchChangeDeparture ------\n", city);
      this.depCity = city;
      this.mescroll.resetUpScroll();
    },
    handleSearchChangeKeyword(keywordStr) {
      console.log("------ keywordStr ------\n", keywordStr);
      this.keywordStr = keywordStr;
      this.mescroll.resetUpScroll();
    },
    JumpeToDetail(item) {
      // this.$router.push({
      //   path: "/tour-group-detail",
      //   query: {
      //     productId: _.get(item, "raw.productId", ""),
      //   },
      // });
      this.$page.push({
        url: "/tour-group-detail",
        name: "tour-group-detail",
        query: {
          productId: _.get(item, "raw.productId", ""),
        },
      });
    },
    handleClickCategory(item) {
      console.log("------ handleClickCategory ------\n", item);
      this.productType = item.code;
      this.mescroll.resetUpScroll();
    },
    loadArrive() {
      if (
        this.$route.query.loadArrive === void 0
          ? false
          : this.$route.query.loadArrive
      ) {
        this.isArriveEmpty = false;
      }
      return Promise.resolve();
    },
    mescrollInit(mescroll) {
      this.mescroll = mescroll;
    },
  },
  created() {
    this.init();
    // Intersection API 用于控制上滑后 category bar 的悬浮展示，用于节省反复计算 scroll 的性能损失
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(
        (entrie) => (this.categoryIntersecting = entrie.isIntersecting)
      );
    });
  },
  mounted() {
    this.observer.observe(this.$refs.category);
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  beforeCreate() {
    if (env.isNative) {
      nativeApi.invoke("updateHeaderRightBtn", {
        action: "hide",
        icon: "",
        text: "",
        data: {},
      });
      nativeApi.invoke("setNavigationBarVisible", { value: "0" });
      nativeApi.invoke("setNavigationBarTintColor", { statusBarStyle: "0" });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/reset.scss";
@import "@/scss/style.scss";
@import "./common.scss";

.category-bar {
  position: fixed;
  top: calc(44px + constant(safe-area-inset-top));
  top: calc(44px + env(safe-area-inset-top));
  z-index: 999;
}

.tour-group {
  background: linear-gradient(180deg, #ffffff, #f2f4f7 9%);
  height: 100%;
  min-height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}

.changePopularDestinations-text {
  font-size: 12 * $px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: rgba(52, 56, 75, 0.5);
}

.changePopularDestinations-icon {
  width: 12 * $px;
  height: 12 * $px;
  margin-left: 8 * $px;
}

.list-card {
  width: 363 * $px;
  background: #fff;
  border-radius: 8 * $px;
  padding: 8 * $px 10 * $px;
  box-sizing: border-box;
  margin-left: 6 * $px;
  margin-bottom: 6 * $px;
}

.mescroll {
  position: fixed;
  top: calc(44px + constant(safe-area-inset-top));
  top: calc(44px + env(safe-area-inset-top));
  bottom: 0;
  height: auto;
}
.mescrollAndriod {
  position: fixed;
  top: 88px;
  width: 375 * $px;
  top: calc(88px + constant(safe-area-inset-top));
  top: calc(88px + env(safe-area-inset-top));
  bottom: 0;
  height: auto;
}
</style>