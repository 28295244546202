<template>
  <div v-if="categoryData && categoryData.length">
    <div :style="{ height: px2rem(16) }"></div>
    <div class="category">
    <ul class="category-list">
      <li
        class="category-list-item"
        v-for="(item, index) in categoryData"
        :key="index"
        @click="goToList(item)"
      >
        <div class="li-item">
          <img :src="item.icon" />
        </div>
        <div class="category-list-item-title">{{ item.name }}</div>
      </li>
    </ul>
  </div>
  </div>
</template>

<script>
import service from "@/service";
import get from "@/utils/get";
import * as LocalData from "@/service/localData";
import { px2rem } from "@/projects/list/mixins";

export default {
  name: "category-list",
  mixins: [px2rem],
  props: {
    dtype: {
      type: String,
      default: "1",
    },
    autoJump: {
      type: Boolean,
      default: true
    },
    JumpTo: {
      type: Function,
      default: () => {}
    },
  },
  data() {
    return {
      categoryData: [],
    };
  },
  created() {
    service
      .r215037({
        dtype: this.dtype,
        depCity: LocalData.getOriginCity().cityName,
        depCityCode: LocalData.getOriginCity().cityCode,
      })
      .then((r) => get(r, "res.bd.data"))
      .then((data) => {
        this.categoryData = data;
      });
  },
  methods: {
    goToList(item) {
      if (this.autoJump) {
        location.href = item.url;
      } else {
        this.JumpTo(item)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/_vars.scss";

.category {
  width: 100%;
  &-list {
    text-align: center;
    overflow-x: scroll;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    &-item {
      width: 20%;
      display: inline-block;
      text-align: center;
      transition: all ease-out 0.2s;
      .li-item {
        width: 46 * $px;
        height: 46 * $px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto 8 * $px;
        img {
          display: block;
          width: 46 * $px;
          height: 46 * $px;
        }
      }
      &-title {
        font-size: 12 * $px;
      }
      &:nth-child(1) .li-item {
        background: linear-gradient(164deg, #0697ff 5%, #5aa3ff 89%);
      }
      &:nth-child(2) .li-item {
        background: linear-gradient(161deg, #ff2e2e 8%, #ff6c6c 88%);
      }
      &:nth-child(3) .li-item {
        background: linear-gradient(161deg, #ffb700 8%, #ffd329 88%);
      }
      &:nth-child(4) .li-item {
        background: linear-gradient(159deg, #ff5e19 11%, #ff9040 86%);
      }
      &:nth-child(5) .li-item {
        background: linear-gradient(161deg, #6762ee 8%, #9490ff 88%);
      }
    }
  }
}
</style>
